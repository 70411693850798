body {
  font-size: 15px !important;
  color: #707070;
  background-color: transparent !important;
}

.menuLink2 {
  text-decoration: none;
}

.courseBreadcrumbsLink {
  text-decoration: none;
}
.courseBreadcrumbsLink:hover {
  text-decoration: underline;
}

.divContenido {
  overflow-y: scroll;
  height: 400px;
}

.divVideo2 {
  clear: both;
  margin-top: 20px;
  padding-top: 0px;
  border: 0px solid #fff;
  width: 100%;
  height: 100%;
}

.react-player2 {
  position: relative;
  top: 0;
  left: 0;
  width: 100% !important;
  text-align: center;
  padding: 0;
  padding-top: 0px;
}

.player-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background-image: url(../images/fondo_video.png);
  border: 1px solid #d6d6d6;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.react-player {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

@media (max-width: 576px) {
}
/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px
*/

.caja {
  overflow-anchor: none;
  border: 1px solid rgb(23, 58, 94);
  position: relative;
  justify-content: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  border-radius: 0;
  display: flex;
  min-height: 48px;
  padding: 0px 16px;
  width: 95%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.active {
  border: 1px solid #001e3c;
}

.active2 {
  overflow-anchor: none;
  border-top: 3px solid #0176de;
  color: #000;
  background-color: #fff !important;
}

.active3 {
  transition: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  overflow: hidden;
  border: 0 !important;
  border-bottom: 0px solid #fec60d !important;
  color: #000;
  background-color: #fff !important;
}

.active4 {
  overflow-anchor: none;
  border-bottom: 1px solid #0176de;
  color: #000;
  background-color: #fff !important;
}

.caja-exercisesClass2 {
  position: relative;
  outline: 0;
  margin: 0;
  margin-bottom: 20px !important;
  padding: 0;
  width: 100% !important;
  border-radius: 10px;
}

.caja-contenido {
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin: 12px 0;
}

.caja-flecha {
  display: flex;
  -webkit-box-flex: 1;
  margin: 12px 0;
}

.link-caja {
  text-decoration: none;
}

.caja-exercisesClass {
  overflow-anchor: none;
  border: 1px solid #0074e9;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  border-radius: 0;
  min-height: 48px;
  padding: 0;
  width: 100% !important;
  margin-bottom: 20px;
  border-radius: 10px;
}

.caja-exercisesClass-title {
  padding: 8px;
  padding-top: 16px;
  padding-bottom: 0;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 48px;
  border-bottom: 1px solid #0074e9;
  font-weight: bold;
  font-size: 1.25rem;
}

.caja-exercisesClass-contenido {
  padding: 16px;
  padding-top: 10px;
}

.caja-exercisesClass-footer {
  padding: 16px;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 48px;
}

.katex-display,
.katex,
.katex-display > .katex > .katex-html {
  display: inline !important;
}

.calendar {
  border: 1px solid rgb(23, 58, 94);
  border-radius: 5px;
}

.MuiCalendarPicker-root {
  border: 1px solid rgb(23, 58, 94);
  border-radius: 5px;
}

.menuLink3 {
  text-decoration: none;
}
.menuLink3:hover,
.menuLink3.activelink {
  text-decoration: none;
  background-color: #e7ebf0;
}

.katex {
  font-size: 1em !important;
}
.katex .mathnormal,
.katex .mathrm,
.katex .custom-Regular,
.katex .amsrm {
  font-family: KaTeX_Math !important;
}

.MuiPickerStaticWrapper-root {
  background-color: transparent !important;
  border: 0px solid #173a5e;
  border-radius: 5px;
}

a,
a:visited {
  color: #0176de;
}

hr {
  border: 0;
  height: 1px;
  width: 100%;
  overflow: hidden;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjEiIHZpZXdCb3g9IjAgMCA4IDEiPiAgPHBvbHlnb24gZmlsbD0iZ3JheSIgZmlsbC1ydWxlPSJldmVub2RkIiBwb2ludHM9IjAgMSAwIDAgMyAwIDMgMSIvPjwvc3ZnPg==)
    center center repeat;
}

.cardHome {
  background-color: #fff;
  width: 250px;
  margin-bottom: 20px;
  border-top: 3px solid #0176de;
  float: left;
  margin-right: 20px;
}
.cardContent {
  min-height: 150px;
}

.link,
.link:visited {
  color: #fff;
}

.labelform {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
}

.exercise-enunciado {
  margin-bottom: 20px;
}
.exercise-enunciado ol,
.exercise-enunciado ul {
  padding: 20px;
}

.exercise-enunciado ol li,
.exercise-enunciado ul li {
  padding-bottom: 10px;
}

/*




*/
.capa-button-login {
  background-color: rgb(255, 255, 255) !important;
  color: rgba(0, 0, 0, 0.54) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  min-width: 214px !important;
  height: 43px !important;
  display: block;
  margin: 0 auto;
  opacity: 1;
  text-transform: uppercase !important;
  overflow: hidden !important;
  padding: 0 !important;
  width: 280px;
  cursor: pointer;
  margin-bottom: 10px;
}
.capa-button-login:hover {
  opacity: 0.8;
  cursor: pointer;
}

.capa-button-login-disabled {
  opacity: 0.5;
  cursor: default;
}

.capa-button-login-disabled:hover {
  opacity: 0.5;
  cursor: default;
}

.left-capa-button-login {
  float: left;
  border: 1px solid transparent;
  left: 0px;
  width: 45px;
  padding: 10px;
  padding-top: 8px;
}
.right-capa-button-login {
  float: left;
  color: #000;
  border: 1px solid transparent;
  width: 230px;
  padding-top: 10px;
}

.icon-uc-login-capa {
  padding-left: 6px;
  padding-top: 5px;
}
.icon-uc-login {
  width: 30px;
}

.MuiBox-root {
  border: 0px solid #000 !important;
  margin: 0 !important;
}

.help-link {
  text-align: center;
  color: #ffffff !important;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.help-link:hover {
  opacity: 0.5;
}
