.loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: #fff !important;
  opacity: 0.4;
  color: #fff;
}

.loading-text {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0px -50px -50px -60px;
  z-index: 10000;
  text-align: center;
}
